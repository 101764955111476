/* eslint-disable no-unused-vars */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import styled from 'styled-components';
import { Link } from 'gatsby';
import MenuSVG from '../../../assets/svg/bars-solid.svg';
import CloseSVG from '../../../assets/svg/close.svg';
import color from '../../../assets/color';
import { BREAK_POINT_MOBILE } from '../../../assets/layout';
import { menuItems } from './HeaderMenu';

const MenuWraper = styled.button`
  background-color: transparent;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  border: 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    height: 40px;
    width: 40px;
  }
`;

const MenuIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MenuIcon = styled(MenuSVG)`
  height: 20px;
  width: 20px;
  color: #a4b1bb;

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    height: 40px;
    width: 40px;
  }
`;

const LinkItem = styled(Link)`
  padding: 10px 15px;
  text-decoration: none;
  color: ${color.text};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 2rem;
  background-color: ${(props) =>
    props.active ? color.primary : 'transparent'};
  border-radius: 5px;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fbfafa;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
`;

const CloseIcon = styled(CloseSVG)`
  height: 20px;
  width: 20px;
`;

const CloseBar = styled.div`
  border: 0;
  border-top: #c6c7ca solid 2px;
  background: transparent;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

function HeaderMenuHamburger({ className }) {
  const [openMenu, setOpenMenu] = useState(false);
  const { pathname } = useLocation();

  const handleToggleMenu = () => {
    if (openMenu) {
      document.body.classList.remove('lockScroll');
    } else {
      document.body.classList.add('lockScroll');
    }
    setOpenMenu(!openMenu);
  };
  const isActive = (inputPath) => {
    const path = pathname.substring(1);
    if (inputPath === '/') {
      return path === '';
    }
    return pathname.indexOf(inputPath) >= 0;
  };
  const renderItems = () =>
    menuItems.map((item) => (
      <LinkItem
        key={item.id}
        to={item.link}
        active={isActive(item.link) ? 1 : 0}
      >
        {item.text}
      </LinkItem>
    ));

  const renderMenu = () => {
    if (!openMenu) return null;
    return (
      <Menu>
        {renderItems()}
        <CloseBar onClick={handleToggleMenu}>
          <CloseIcon />
        </CloseBar>
      </Menu>
    );
  };

  return (
    <MenuWraper className={className} onClick={handleToggleMenu}>
      <MenuIconWrapper><MenuIcon /></MenuIconWrapper>
      {renderMenu()}
    </MenuWraper>
  );
}

HeaderMenuHamburger.propTypes = {
  className: PropTypes.string,
};

HeaderMenuHamburger.defaultProps = {
  className: '',
};

export default HeaderMenuHamburger;
