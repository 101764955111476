/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import styled from 'styled-components';
import { Link } from 'gatsby';
import color from '../../../assets/color';

const Wrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

const LinkItem = styled(Link)`
  padding: 10px 15px;
  text-decoration: none;
  color: ${(props) => (props.active ? '#FFFFFF' : color.text)};
  display: flex;
  align-items: center;
  margin-left: 0rem;
  background-color: ${(props) =>
    props.active ? color.primary : 'transparent'};
  border-radius: 5px;
  &:hover {
    color: ${(props) => (props.active ? '#FFFFFF' : color.text)};
  }
`;

export const menuItems = [
  {
    id: 'info',
    link: '/',
    text: '議題資訊',
  },
  {
    id: 'resource',
    link: '/resource',
    text: '政府資源',
  },
  {
    id: 'action',
    link: '/action',
    text: '創新行動',
  },
  {
    id: 'vote',
    link: '/vote',
    text: '參與投票',
  },
];

function HeaderMenu({ className }) {
  const { pathname } = useLocation();

  const isActive = (inputPath) => {
    const path = pathname.substring(1);
    if (inputPath === '/') {
      return path === '';
    }
    return pathname.indexOf(inputPath) >= 0;
  };

  const renderItems = () =>
    menuItems.map((item) => (
      <LinkItem
        key={item.id}
        to={item.link}
        active={isActive(item.link) ? 1 : 0}
      >
        {item.text}
      </LinkItem>
    ));
  return <Wrapper className={className}>{renderItems()}</Wrapper>;
}

HeaderMenu.propTypes = {
  className: PropTypes.string,
};

HeaderMenu.defaultProps = {
  className: '',
};

export default HeaderMenu;
