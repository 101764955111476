/* eslint-disable operator-linebreak */
/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import ContactForm from './ContactForm';
import MainContainer from '../Universal/Container/MainContainer';
import { H4 } from '../Universal/Title/H';
import { BREAK_POINT_MOBILE, BREAK_POINT_TABLET } from '../../assets/layout';
import Logo from '../Universal/Logo/Logo';
import LogoYonglin from '../../assets/images/logo_yonglin_footer.png';
import LogoYonglinMobile from '../../assets/images/logo_yonglin_footer_mobile.png';
import LogoSeinsights from '../../assets/images/logo_sei_top.png';
import LogoSeinsightsMobile from '../../assets/images/logo_sei_footer_mobile.png';
import { useViewport } from '../../hooks/useViewport';

const Titile = styled(H4)`
  text-align: center;
  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    text-align: left;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 80px;
  border-top: #e0e4e7 solid 1px;
`;

const Main = styled(MainContainer)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
`;

const Block = styled.div`
  width: 100%;
  padding: 2.5rem;
  padding-bottom: 0rem;

  @media only screen and (min-width: ${BREAK_POINT_TABLET}) {
    padding: 1rem;
    height: 22rem;
    width: 45%;
  }
`;

const BlockLeft = styled(Block)`
  position: relative;
`;

const TextBlock = styled.div`
  margin-bottom: 1rem;
`;

const LogoBlock = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
`;

const LogoMobileBlock = styled.div`
  display: flex;
  margin-top: 5rem;
`;

const Disc = styled.p`
  text-align: justify;
  margin-bottom: 0rem;
  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    margin-bottom: 4rem;
  }
`;

const P = styled.p`
  text-align: justify;
`;

const Noti = styled.p`
  text-align: justify;
  font-size: 12px;
  font-weight: normal;

  a {
    text-decoration: underline;
    color: #0000ee;
  }

  a:hover {
    text-decoration: underline;
    color: #0000ee;
  }
`;

const NOTI_LINK =
  'https://drive.google.com/file/d/1a6cSbEtFfmndFPy91TIKxCWVFFzOqpwr/view?usp=sharing';

function Contact() {
  const [viewport] = useViewport();

  return (
    <Wrapper>
      <Main>
        <BlockLeft>
          <TextBlock>
            <Titile>明日戶政所——少子女化全解析</Titile>
            <Disc>
              永齡基金會與社企流攜手，打造明日戶政所，提供少子女化議題現況、彙整政府資源與國內外創新行動。我們相信生兒育女是每個人自由的選擇與權利，邀請你一起從關注議題開始，為促進友善兼容的生育環境踏出第一步！
            </Disc>
          </TextBlock>
          {viewport === 'mobile' ? null : (
            <LogoBlock>
              <Logo
                image={LogoYonglin}
                alt="永寧基金會"
                link="https://bit.ly/32VWdem"
              />
              <Logo
                image={LogoSeinsights}
                alt="社企流"
                link="https://bit.ly/38TCOyA"
              />
            </LogoBlock>
          )}
        </BlockLeft>
        <Block>
          <TextBlock>
            <Titile>填寫聯絡方式，獲得最新資訊</Titile>
            <P>
              留下聯繫方式或想說的話，未來若有最新資訊／活動，將第一時間通知你！
            </P>
            <Noti>
              為確保您的權益，請先詳閱
              <a href={NOTI_LINK} target="_blank" rel="noreferrer">
                個人資料保護法
              </a>
              告知說明，當您將以下資料送出，表示您已確認詳閱並同意個人資料保護法告知事項。
            </Noti>
          </TextBlock>
          <ContactForm />
        </Block>
        {viewport === 'mobile' ? (
          <LogoMobileBlock>
            <Logo
              image={LogoYonglinMobile}
              alt="永寧基金會"
              link="https://bit.ly/32VWdem"
            />
            <Logo
              image={LogoSeinsightsMobile}
              alt="社企流"
              link="https://bit.ly/38TCOyA"
            />
          </LogoMobileBlock>
        ) : null}
      </Main>
    </Wrapper>
  );
}

export default Contact;
