import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import LoadingIcon from '../../../assets/icons/loading.svg';

// Create the keyframes
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoadingWrapper = styled.div`
  display: inline-block;
  animation: ${rotate} 2s linear infinite;
  width: 20px;
  height: 20px;
  opacity: 0.6;
`;

function Loading({ className }) {
  return (
    <LoadingWrapper className={className}>
      <img src={LoadingIcon} alt="loading" />
    </LoadingWrapper>
  );
}

Loading.propTypes = {
  className: PropTypes.string,
};

Loading.defaultProps = {
  className: '',
};

export default Loading;
