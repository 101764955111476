/* eslint-disable semi */
/* eslint-disable comma-dangle */
export default {
  npm_package_devDependencies_prettier: '2.0.5',
  npm_package_devDependencies_gatsby_plugin_eslint: '^2.0.8',
  TERM_PROGRAM: 'vscode',
  NODE: '/Users/oen/.nvm/versions/node/v12.20.0/bin/node',
  npm_package_dependencies_gatsby_plugin_sharp: '^2.6.27',
  INIT_CWD: '/Users/oen/Dev/github/watchout/bu-yonglin-feature-household',
  NVM_CD_FLAGS: '-q',
  npm_package_dependencies_axios: '^0.20.0',
  npm_config_version_git_tag: 'true',
  TERM: 'xterm-256color',
  SHELL: '/usr/local/bin/zsh',
  npm_package_dependencies_gatsby_source_filesystem: '^2.3.24',
  TMPDIR: '/var/folders/ql/m277nwvn7w7f94tkcrq6l_m40000gn/T/',
  npm_package_dependencies_gatsby_plugin_styled_components: '^3.3.10',
  npm_config_init_license: 'MIT',
  TERM_PROGRAM_VERSION: '1.65.0',
  npm_package_devDependencies_eslint_plugin_flowtype: '^5.2.0',
  npm_package_dependencies_gatsby_plugin_sass: '^2.8.0',
  npm_package_scripts_dev: 'gatsby develop -p 3001 -H 0.0.0.0',
  ORIGINAL_XDG_CURRENT_DESKTOP: 'undefined',
  npm_package_private: 'true',
  npm_config_registry: 'https://registry.yarnpkg.com',
  ZSH: '/Users/oen/.oh-my-zsh',
  npm_package_dependencies_react_dom: '^16.12.0',
  npm_package_readmeFilename: 'README.md',
  npm_package_devDependencies_eslint_plugin_jsx_a11y: '^6.3.1',
  NVM_DIR: '/Users/oen/.nvm',
  USER: 'oen',
  npm_package_description: 'yonglin-feature-household',
  npm_config_python: '/usr/local/opt/python@3.9/bin/python3',
  COMMAND_MODE: 'unix2003',
  npm_package_devDependencies_eslint_loader: '^4.0.2',
  SSH_AUTH_SOCK: '/private/tmp/com.apple.launchd.cfx4m1ZGTI/Listeners',
  npm_package_dependencies_react_helmet: '^6.1.0',
  npm_package_dependencies_lodash: '^4.17.20',
  npm_package_dependencies_babel_plugin_styled_components: '^1.11.1',
  __CF_USER_TEXT_ENCODING: '0x1F5:0x2:0x35',
  npm_package_devDependencies_eslint: '^7.7.0',
  npm_package_dependencies_styled_components: '^5.1.1',
  npm_execpath: '/usr/local/Cellar/yarn/1.22.17/libexec/bin/yarn.js',
  PAGER: 'less',
  npm_package_dependencies_gatsby_plugin_google_gtag: '^2.5.0',
  npm_package_author_name: 'WatchoutTW',
  LSCOLORS: 'Gxfxcxdxbxegedabagacad',
  npm_package_dependencies_prop_types: '^15.7.2',
  PATH: '/var/folders/ql/m277nwvn7w7f94tkcrq6l_m40000gn/T/yarn--1646637253366-0.1684472508512158:/Users/oen/Dev/github/watchout/bu-yonglin-feature-household/node_modules/.bin:/Users/oen/.config/yarn/link/node_modules/.bin:/Users/oen/.nvm/versions/node/v12.20.0/libexec/lib/node_modules/npm/bin/node-gyp-bin:/Users/oen/.nvm/versions/node/v12.20.0/lib/node_modules/npm/bin/node-gyp-bin:/Users/oen/.nvm/versions/node/v12.20.0/bin/node_modules/npm/bin/node-gyp-bin:/Users/oen/.nvm/versions/node/v12.20.0/bin:/Users/oen/Dev/github/phacility/arcanist/bin:/usr/local/bin:/usr/bin:/bin:/usr/sbin:/sbin:/Users/oen/.nvm/versions/node/v12.20.0/bin:/Users/oen/Dev/github/phacility/arcanist/bin',
  npm_config_argv: '{"remain":[],"cooked":["run","build"],"original":["build"]}',
  _: '/Users/oen/Dev/github/watchout/bu-yonglin-feature-household/node_modules/.bin/gatsby',
  npm_package_dependencies_gatsby_plugin_manifest: '^2.4.23',
  npm_config__remix_run_registry: 'https://npm.remix.run',
  __CFBundleIdentifier: 'com.microsoft.VSCode',
  npm_package_dependencies_react_toastify: '^6.1.0',
  PWD: '/Users/oen/Dev/github/watchout/bu-yonglin-feature-household',
  npm_package_devDependencies_eslint_config_airbnb: '^18.2.0',
  npm_package_devDependencies_eslint_plugin_react_hooks: '^4.1.0',
  npm_package_dependencies_node_sass: '4.14.1',
  npm_package_dependencies_gatsby_plugin_react_svg: '^3.0.0',
  npm_lifecycle_event: 'build',
  LANG: 'zh_TW.UTF-8',
  npm_package_keywords_0: 'gatsby',
  npm_package_dependencies_gatsby_transformer_sharp: '^2.5.13',
  npm_package_name: 'yonglin-feature-household',
  npm_package_devDependencies_eslint_plugin_import: '^2.22.0',
  npm_package_scripts_build: 'gatsby clean && gatsby build',
  npm_config_version_commit_hooks: 'true',
  VSCODE_GIT_ASKPASS_EXTRA_ARGS: '--ms-enable-electron-run-as-node',
  XPC_FLAGS: '0x0',
  npm_config_bin_links: 'true',
  npm_package_dependencies_react_use_clipboard: '1.0.2',
  XPC_SERVICE_NAME: '0',
  npm_package_dependencies_react_device_detect: '^1.17.0',
  npm_package_dependencies_chart_js: '^2.9.3',
  npm_package_version: '1.0.0',
  SHLVL: '2',
  HOME: '/Users/oen',
  VSCODE_GIT_ASKPASS_MAIN: '/Applications/Visual Studio Code.app/Contents/Resources/app/extensions/git/dist/askpass-main.js',
  npm_package_dependencies_html_to_react: '^1.4.5',
  npm_package_scripts_serve: 'gatsby serve -p 3001',
  npm_package_dependencies_gatsby_plugin_react_helmet: '^3.3.10',
  npm_package_dependencies_formik: '^2.2.1',
  npm_package_dependencies_dotenv: '^8.2.0',
  npm_config_save_prefix: '^',
  npm_config_strict_ssl: 'true',
  npm_package_devDependencies_babel_eslint: '^10.1.0',
  npm_package_dependencies_nanoid: '^3.1.20',
  npm_config_version_git_message: 'v%s',
  NPM_CONFIG_PYTHON: '/usr/local/opt/python@3.9/bin/python3',
  npm_package_dependencies_gatsby: '^2.24.47',
  npm_package_dependencies_d3: '^6.3.0',
  LESS: '-R',
  LOGNAME: 'oen',
  YARN_WRAP_OUTPUT: 'false',
  npm_package_dependencies__reach_router: '^1.3.4',
  PREFIX: '/usr/local',
  npm_lifecycle_script: 'gatsby clean && gatsby build',
  VSCODE_GIT_IPC_HANDLE: '/var/folders/ql/m277nwvn7w7f94tkcrq6l_m40000gn/T/vscode-git-fc04b5cac3.sock',
  npm_package_dependencies_yup: '^0.29.3',
  npm_package_dependencies_gatsby_plugin_offline: '^3.2.23',
  npm_package_dependencies_react: '^16.12.0',
  NVM_BIN: '/Users/oen/.nvm/versions/node/v12.20.0/bin',
  npm_package_dependencies__react_hook_window_scroll: '^1.3.0',
  npm_config_version_git_sign: '',
  npm_config_ignore_scripts: '',
  npm_config_user_agent: 'yarn/1.22.17 npm/? node/v12.20.0 darwin x64',
  VSCODE_GIT_ASKPASS_NODE: '/Applications/Visual Studio Code.app/Contents/MacOS/Electron',
  GIT_ASKPASS: '/Applications/Visual Studio Code.app/Contents/Resources/app/extensions/git/dist/askpass.sh',
  npm_package_dependencies_react_chartjs_2: '^2.10.0',
  npm_package_dependencies_qs: '^6.9.4',
  npm_package_dependencies_gatsby_image: '^2.4.16',
  npm_config__OEN_Tech_registry: 'https://npm.pkg.github.com/',
  npm_config_init_version: '1.0.0',
  npm_config_ignore_optional: '',
  npm_package_scripts_clean: 'gatsby clean',
  npm_package_dependencies_react_select: '^3.1.1',
  npm_package_dependencies_shortid: '^2.2.15',
  npm_package_dependencies_react_bubble_chart: '^0.4.0',
  COLORTERM: 'truecolor',
  npm_node_execpath: '/Users/oen/.nvm/versions/node/v12.20.0/bin/node',
  npm_package_devDependencies_eslint_plugin_react: '^7.20.6',
  npm_config_version_tag_prefix: 'v',
  GATSBY_LOGGER: 'ink',
  gatsby_log_level: 'normal',
  gatsby_executing_command: 'build',
  NODE_ENV: 'production',
  VIPSHOME: '/usr/local/Cellar/vips/8.9.1',
  FH_SITE_URL: 'https://fertility.tw',
  FH_INFOGRAPHIC_SHEET_ID: '1LDMNfWLg1DyUULEOFmNXdj3K6rNXPPKMUC7UxFxC88c',
  FH_RESOURCE_SHEET_ID: '1lnr0RIUY8ZzWXNWOq_Smc90aCk2AbD0tWpghkRn67xI',
  FH_VOTE_1_SHEET_ID: '1LW8kdNFne70jdnlezh3KGE_bRfCN59Jb26lIIKLrwXY',
  FH_VOTE_2_SHEET_ID: '1elsJ60UswtsVKTe-vI1OeldcfXQafqa3tKddEh4XiMk',
  FH_VOTE_3_SHEET_ID: '17YzEmNIH7SSfnffgNuCz1nUQz2EEj6Xqtw41n9JvgbE',
  FH_CONTACT_ACTION_URL: 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSf2U_cqaZxSBQNJ81oUOrl9XrH9ZmDna7hEpEbndnqK0yJ6tw/formResponse',
  FH_CONTACT_ENTRY_EMAIL: 'entry.1875006575',
  FH_CONTACT_ACTION_NAME: 'entry.384776954',
  FH_CONTACT_ACTION_COMMENT: 'entry.67791315',
  FH_VOTE_SHARE_1_ACTION_URL: 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSdqxt4CNXGM8vZSdrI8Zk6eC6D3I9I2GLAQs_TwLsWU_bvycA/formResponse',
  FH_VOTE_SHARE_1_ENTRY: 'entry.918093334',
  FH_VOTE_SHARE_2_ACTION_URL: 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSc1oU0Jn68pZkGDzXxaFMCUsszwr1lQ42DkUD75M93vi1yDtQ/formResponse',
  FH_VOTE_SHARE_2_ENTRY: 'entry.548938881',
  FH_VOTE_SHARE_3_ACTION_URL: 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSeY6I1UOYVzeCsNVhTAMP9dePDgqWKPXO0xvXd-ceJYcuelSw/formResponse',
  FH_VOTE_SHARE_3_ENTRY: 'entry.591455229'
}
