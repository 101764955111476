import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useClipboard from 'react-use-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IconFacebook from '../../../assets/icons/share-fb.png';
import IconLine from '../../../assets/icons/share-line.png';
import IconlinkImg from '../../../assets/icons/link-solid.svg';
import { BREAK_POINT_MOBILE, BREAK_POINT_TABLET } from '../../../assets/layout';

const Wrapper = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 50px;
  z-index:10;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    right: 1rem;
    top: 80px;
  }

  @media only screen and (min-width: ${BREAK_POINT_TABLET}) {
    right: 3rem;
    top: 80px;
  }  
`;

const ShareItems = styled.div`
  font-size: 14px;
  margin-top: 10px;
  width: 88px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 3px 10px 0 rgba(74, 74, 74, 0.2);
  cursor: pointer;
`;

const ShareTo = styled.div`
  padding: 0.5rem 1rem;
  text-align: center;
`;

const Arrow = styled.div`
  position: absolute;
  top: 0;
  left: 50px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 10px 12px;
  border-color: transparent transparent #ffffff transparent;
  margin-bottom: 29px;
`;

const ShareItem = styled.div`
  height: 68px;
  background-size: contain;
`;

const LinkItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
`;

const IconLink = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: 5px;
`;

const linkTo = 'http://bit.ly/3hw4sUx';

const Quote = '明日戶政所——少子女化全解析 ';
const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${linkTo}&quote=${Quote}`;
const LineShareLink = `http://line.naver.jp/R/msg/text/?${Quote}%0D%0A ${linkTo}`;
const copyShareLink = `${Quote}\n${linkTo}`;

function SocialShare({ className }) {
  const notify = () => toast('網址已複製！');
  const [isCopied, setCopied] = useClipboard(copyShareLink, {
    successDuration: 1000,
  });
  useEffect(() => {
    if (isCopied) {
      notify();
    }
  }, [isCopied]);

  return (
    <Wrapper className={className}>
      <Arrow />
      <ShareItems>
        <ShareTo>分享到</ShareTo>
        <a target="_blank" rel="noreferrer" href={facebookShareLink}>
          <ShareItem style={{ backgroundImage: `url(${IconFacebook})` }} />
        </a>
        <a target="_blank" rel="noreferrer" href={LineShareLink}>
          <ShareItem style={{ backgroundImage: `url(${IconLine})` }} />
        </a>
        <LinkItem onClick={setCopied}>
          <IconLink src={IconlinkImg} alt="" />
          <div>複製網址</div>
        </LinkItem>
      </ShareItems>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
      />
    </Wrapper>
  );
}

SocialShare.propTypes = {
  className: PropTypes.string,
};

SocialShare.defaultProps = {
  className: '',
};

export default SocialShare;
