/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BREAK_POINT_MOBILE } from '../../../assets/layout';

const Wrapper = styled.a`
  cursor: pointer;
  margin-right: 1rem;
  &:last-child {
    margin-right: 0;
  }
`;

const Logo = styled.img`
  height: 20px;
  display: flex;

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    margin-right: 2rem;
    height: 40px;
  }
`;

function Header(props) {
  const { className, image, alt, link } = props;
  return (
    <Wrapper className={className} href={link} target="_blank" rel="noreferrer">
      <Logo src={image} alt={alt} />
    </Wrapper>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  alt: PropTypes.string,
  link: PropTypes.string,
};

Header.defaultProps = {
  className: '',
  image: '',
  alt: '',
  link: '',
};

export default Header;
