import React from 'react';
import styled from 'styled-components';
import { BREAK_POINT_MOBILE } from '../../assets/layout';

const Wrapper = styled.a`
  position: fixed;
  left: 0;
  bottom: 38%;
  border: solid 1px #0daab5;
  border-left: 0;
  border-radius: 0px 4px 4px 0px;
  color: #0daab5;
  width: 1.5rem;
  text-align: center;
  padding: 0.3rem;
  cursor: pointer;
  z-index: 99;
  background-color: rgba(255,255,255,0.8);

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    bottom: 0;
    width: auto;
    padding: 1rem 2rem;
    border-radius: 0px 4px 0px 0px;
    border-left: 0;
    border-bottom: 0;
  }
`;

function ScrollToTop() {
  return (
    <Wrapper href="#top">
      回到頂端
    </Wrapper>
  );
}

export default ScrollToTop;
