import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import HeaderLogo from '../../../assets/images/header_logo.png';
import HeaderLogoMobile from '../../../assets/images/header_logo_mobile.png';
import { BREAK_POINT_MOBILE, BREAK_POINT_TABLET } from '../../../assets/layout';
import { useViewport } from '../../../hooks/useViewport';

const Wrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  margin-left: 1rem;

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    height: 80px;
  }

  @media only screen and (min-width: ${BREAK_POINT_TABLET}) {
    margin-left: 3rem;
  }
`;

const LogoWrapper = styled.a`
  cursor: pointer;
  margin-right: 1rem;
  &:last-child {
    margin-right: 0;
  }
`;

const Logo = styled.img`
  height: 40px;
  display: flex;

  @media only screen and (min-width: ${BREAK_POINT_TABLET}) {
    height: 68px;
  }
`;

function HeaderLeft({ className }) {
  const [viewport] = useViewport();
  return (
    <Wrapper className={className}>
      <LogoWrapper href="http://bit.ly/3pD6GEo">
        <Logo src={viewport !== 'desktop' ? HeaderLogoMobile : HeaderLogo} alt="明日戶政所" />
      </LogoWrapper>
    </Wrapper>
  );
}

HeaderLeft.propTypes = {
  className: PropTypes.string,
};

HeaderLeft.defaultProps = {
  className: '',
};

export default HeaderLeft;
