/* eslint-disable comma-dangle */
/* eslint-disable implicit-arrow-linebreak */
import axios from 'axios';
import qs from 'qs';

const SHEET_BASE_URL = 'https://sheets.googleapis.com/v4/spreadsheets';
const QUERY_STRING = 'A%3AM?key=AIzaSyBkVSguhj9zn8KZ0FmfsDvN9jr4kZhxg54';
// const CORS_PROXY = 'https://cors_tunnel.weilie.workers.dev/proxy/?apiurl=';
// const CORS_PROXY = 'https://fertility.tw/test.php?apiurl=';

const GOOGLE_SHEET_CONFIG = {
  baseURL: SHEET_BASE_URL,
  timeout: 30000,
};

const googleSheetDataRequest = axios.create(GOOGLE_SHEET_CONFIG);

export const getGoogleSheetData = (sheetID, sheetName = null) =>
  googleSheetDataRequest.get(
    `${SHEET_BASE_URL}/${sheetID}/values/${sheetName ? `${sheetName}!` : ''}${QUERY_STRING}`
  );

export const postGoogleForm = (actionURL, formData = []) => {
  const bodyFormData = {};
  formData.forEach((item) => {
    if (bodyFormData[item.entry]) {
      bodyFormData[item.entry].push(item.value);
    } else {
      bodyFormData[item.entry] = [];
      bodyFormData[item.entry].push(item.value);
    }
    // bodyFormData.append(item.entry, item.value);
  });
  // console.log('bodyFormData1 :', bodyFormData);
  const postData = qs.stringify(bodyFormData, { indices: false });
  // console.log('bodyFormData2 :', qs.stringify(bodyFormData));

  return fetch(`${actionURL}?&${postData}&submit=SUBMIT`, {
    method: 'POST',
    mode: 'no-cors', // Google will only submit a form if 'mode' is 'no-cors'
    redirect: 'follow',
    referrer: 'no-referrer',
  });

  /*
  return axios({
    method: 'POST',
    data: qs.stringify(formData),
    url: `${CORS_PROXY}${actionURL}`,
  });
  */
};

export default axios;
