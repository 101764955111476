import styled from 'styled-components';
import { BREAK_POINT_DESKTOP } from '../../../assets/layout';

const MainContainer = styled.div`
  max-width: ${BREAK_POINT_DESKTOP};
  margin: 0 auto;

  @media only screen and (min-width: ${BREAK_POINT_DESKTOP}) {
    
  }
`;

export default MainContainer;
