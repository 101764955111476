/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable object-curly-newline */
/* eslint-disable-next-line object-curly-newline */
import React from 'react';
import styled from 'styled-components';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import usePostGoogleForm from '../../hooks/usePostGoogleForm';
import env from '../../env';
import { BREAK_POINT_MOBILE } from '../../assets/layout';
import Loading from '../Universal/Loading/Loading';

const Wrapper = styled.div`
  width: 100%;
  max-width: 560px;
  color: #404a5b;
`;

const FieldWrapper = styled.div`
  margin-bottom: 2rem;
  position: relative;
`;

const FieldInput = styled(Field)`
  background-color: #fbfafa;
  width: 99%;
  height: 2.5rem;
  border: 0;
  border-bottom: solid 1px #e0e4e7;
  :focus {
    outline: none;
  }
`;

const FieldError = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: -1.5em;
  color: #ff0000;
`;

const SubmitWrapper = styled.div`
  text-align: center;
  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    text-align: left;
  }
`;

const SubmitBtn = styled.button`
  background: transparent;
  padding: 13px 72px 12px;

  border-radius: 4px;
  border: solid 1px #50d9e3;
  cursor: pointer;
`;

const LoadingBtn = styled(Loading)`
  margin-left: 1.5rem;
  margin-right: 1.5rem;
`;

const SentInfo = styled.div`
  color: #e0e4e7;
`;

const initialValues = {
  email: '',
  name: '',
  comment: '',
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email('不正確的電子郵件格式！')
    .required('請填入電子郵件！'),
  name: Yup.string().required('請填入你的稱呼'),
  comment: Yup.string(),
});

function ContactForm() {
  const [formRes, formLoading, , sendFromRequest] = usePostGoogleForm();

  const handleSummit = (values, { setSubmitting }) => {
    const payload = [
      {
        entry: env.FH_CONTACT_ENTRY_EMAIL,
        value: values.email,
      },
      {
        entry: env.FH_CONTACT_ACTION_NAME,
        value: values.name,
      },
      {
        entry: env.FH_CONTACT_ACTION_COMMENT,
        value: values.comment,
      },
    ];
    sendFromRequest(env.FH_CONTACT_ACTION_URL, payload);
    setSubmitting(false);
  };

  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSummit}
      >
        <Form>
          <FieldWrapper>
            <FieldInput name="email" type="email" placeholder="E-mail" />
            <ErrorMessage
              name="email"
              render={(msg) => <FieldError>{msg}</FieldError>}
            />
          </FieldWrapper>
          <FieldWrapper>
            <FieldInput name="name" type="text" placeholder="姓名" />
            <ErrorMessage
              name="name"
              render={(msg) => <FieldError>{msg}</FieldError>}
            />
          </FieldWrapper>
          <FieldWrapper>
            <FieldInput
              name="comment"
              type="textarea"
              placeholder="請留下你的想法"
            />
            <ErrorMessage
              name="comment"
              render={(msg) => <FieldError>{msg}</FieldError>}
            />
          </FieldWrapper>
          <SubmitWrapper>
            {formRes ? (
              <SentInfo>已送出 ！ 謝謝您寶貴的想法！</SentInfo>
            ) : (
              <SubmitBtn type="submit" disabled={formLoading}>
                {formLoading ? <LoadingBtn /> : '送出資訊'}
              </SubmitBtn>
            )}
          </SubmitWrapper>
        </Form>
      </Formik>
    </Wrapper>
  );
}

export default ContactForm;
