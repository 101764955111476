import { useState, useCallback } from 'react';
import { postGoogleForm } from '../utils/api';

export default function usePostGoogleForm() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [res, setRes] = useState(null);

  const sendRequest = useCallback(
    async (actionURL, formData) => {
      try {
        setError(null);
        setLoading(true);
        await postGoogleForm(actionURL, formData);
        // console.log('tres :', tres);
        setRes({ result: 'OK' });
        setLoading(false);
      } catch (e) {
        setError(`ERROR : ${JSON.stringify(e)}`);
        setLoading(false);
      }
    },
    [],
  );

  return [res, loading, error, sendRequest];
}
