import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconShare from '../../../assets/icons/icon_share.png';
import HeaderMenu from './HeaderMenu';
import HeaderMenuHamburger from './HeaderMenuHamburger';
import SocialShare from '../SocialShare/SocialShare';
import { useViewport } from '../../../hooks/useViewport';
import { BREAK_POINT_MOBILE, BREAK_POINT_TABLET } from '../../../assets/layout';

const Wrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  margin-right: 1rem;

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    height: 80px;
  }

  @media only screen and (min-width: ${BREAK_POINT_TABLET}) {
    margin-right: 3rem;
  }
`;

const Icon = styled.img`
  height: 20px;
  display: flex;

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    height: 30px;
  }
`;

const Btn = styled.button`
  height: 36px;
  border: 0;
  background: 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    height: 40px;
  }
`;

function HeaderRight({ className }) {
  const [viewPort] = useViewport();
  const [share, setShare] = useState(false);

  const handleToggleShareMenu = () => {
    setShare(!share);
  };

  const isHamburger = () => (viewPort !== 'desktop');

  return (
    <Wrapper className={className}>
      { isHamburger() ? <HeaderMenuHamburger /> : <HeaderMenu /> }
      <Btn onClick={handleToggleShareMenu}>
        <Icon src={IconShare} alt="" />
      </Btn>
      {share ? <SocialShare /> : null}
    </Wrapper>
  );
}

HeaderRight.propTypes = {
  className: PropTypes.string,
};

HeaderRight.defaultProps = {
  className: '',
};

export default HeaderRight;
