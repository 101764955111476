import styled from 'styled-components';
import color from '../../../assets/color';
import { BREAK_POINT_MOBILE } from '../../../assets/layout';

export const H1 = styled.h1`
  font-size: 2.25rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: ${color.text};
  margin: 0;

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    font-size: 4.8rem;
  }
`;

export const H2 = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.22px;
  color: ${color.text};
  margin: 0;

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    font-size: 3.2rem;
  }
`;

export const H3 = styled.h3`
  font-size: 1.25rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.15px;
  color: ${color.text};
  margin: 0;
  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    font-size: 1.8rem;
  }
`;

export const H4 = styled.h3`
  font-size: 1.25rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.15px;
  color: ${color.text};
  margin: 0;
  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    font-size: 1.2rem;;
  }
`;
