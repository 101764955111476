import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BREAK_POINT_MOBILE } from '../../../assets/layout';
import HeaderLeft from './HeaderLeft';
import HeaderRight from './HeaderRight';

const Wrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px 0 #e0e4e7;
  background-color: #FFFFFF;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    height: 80px;
  }
`;

function Header({ className }) {
  return (
    <Wrapper className={className}>
      <HeaderLeft />
      <HeaderRight />
    </Wrapper>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

Header.defaultProps = {
  className: '',
};

export default Header;
