/* eslint-disable object-curly-newline */
import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

const BREAK_POINT_MOBILE = 576;
const BREAK_POINT_TABLET = 992;

const ViewportContext = createContext({ viewPort: 'mobile', windowWidth: 0, windowHeight: 0 });

export const useViewport = () => {
  const { viewPort, windowWidth, windowHeight } = useContext(ViewportContext);
  return [viewPort, windowWidth, windowHeight];
};

export const ViewportProvider = ({ children }) => {
  const [windowWidth, setWidth] = useState(typeof window !== 'undefined' && window.innerWidth);
  const [windowHeight, setHeight] = useState(typeof window !== 'undefined' && window.innerHeight);
  const [viewPort, setViewProt] = useState('mobile');

  useEffect(() => {
    const handleWindowResize = () => {
      const wWidth = typeof window !== 'undefined' && window.innerWidth;
      const wHeight = typeof window !== 'undefined' && window.innerHeight;

      setWidth(wWidth);
      setHeight(wHeight);

      if (wWidth <= BREAK_POINT_MOBILE) {
        setViewProt('mobile');
      } else if (wWidth <= BREAK_POINT_TABLET) {
        setViewProt('tablet');
      } else {
        setViewProt('desktop');
      }
    };
    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [viewPort]);

  return (
    <ViewportContext.Provider value={{ viewPort, windowWidth, windowHeight }}>
      {children}
    </ViewportContext.Provider>
  );
};

ViewportProvider.propTypes = {
  children: PropTypes.node,
};

ViewportProvider.defaultProps = {
  children: null,
};
